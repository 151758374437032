<template>
  <a href="/"><img class="mx-auto md:mx-0 object-fill h-24 w-64" alt="SmartAware.de logo" src="./assets/smartaware_logo.svg"></a>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
